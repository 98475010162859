<template>
  <div class="page">
    <Nav></Nav>
    <main class="wrap">
      <div class="main">
        <div class="m-panel">
          <div class="hd">
            收货人信息
          </div>
          <div class="bd">
            <div class="l">
              <div class="item">
                <div class="label">收 货 人：</div>
                <div class="txt">{{ receive.name }}</div>
              </div>
              <div class="item">
                <div class="label">联系方式：</div>
                <div class="txt">{{ receive.mobile }}</div>
              </div>
              <div class="item">
                <div class="label">收货地址：</div>
                <div class="txt">{{ receive.areaInfo }}{{ receive.address }}</div>
              </div>
            </div>
            <div class="r">
              <div>
                <a-button type="link" @click="selectAddress">
                  切换地址
                </a-button>
              </div>
              <div>
                <a-button type="default" @click="addAddress">
                  新建地址
                </a-button>
              </div>
            </div>
          </div>
        </div>
        <div class="m-panel">
          <div class="hd">
            <div class="info">商品信息</div>
            <div class="t1">单价</div>
            <div class="t1">数量</div>
            <div class="t1">小计</div>
          </div>
          <div>
            <div class="goods" v-for="(item, index) in goods" :key="index">
              <div class="img"><img :src="item.imgSrc" width="100" height="100" /></div>
              <div class="title">
                <div style="height: 25px;">
                {{ item.title }}
              </div>
              <div style="color: #999;">{{ item.specName }}</div>
              </div>
              <div class="pr">¥{{ item.price }}</div>
              <div class="t1">{{ item.count }}</div>
              <div class="pr">¥{{ item.price * item.count }}</div>
            </div>
          </div>
        </div>
        <div class="m-panel pd10">
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">商品金额：</div>
              <div class="txt">¥{{ productTotal }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">邮 费：</div>
              <div class="txt">¥{{ freight }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <div class="m-itemsInfo">
              <div class="label">应付：</div>
              <div class="price">¥{{ total }}</div>
            </div>
          </div>
          <div class="m-itemInfoFt">
            <a-button type="primary" class="order" @click="buy">
              结算
            </a-button>
          </div>
        </div>
      </div>
    </main>
    <foot></foot>
  </div>
</template>

<script>

import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";

export default {
  name: "Home",
  components: {
    Nav,
    foot,
  },
  data() {
    return {
      coupon_val: 0,
      freight: 0,
      goods: [],
      receive: {
        name: '',
        mobile: '',
        address: '',
        areaInfo: '',
      },
      productTotal: 0,
      total: 0,
      remark: '',
    };
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  created() {
    this.fetchDate();
  },
  methods: {
    //请求数据
    fetchDate() {
      let order = this.storage.getItem("order");
      if (order) {
        let receive = order.receive;
        if (receive) {
          this.receive = receive;
        }
      } else {
        this.order = {};
        this.storage.setItem("order", order);
      }
      if (order.product && order.product.length > 0) {
        this.goods = order.product;
      }
      else {
        this.$router.push("/ShoppingCart");
      }

      this.totalPrice();
    },
    //金额统计
    totalPrice() {
      let total = 0;
      this.goods.forEach(item => {
        total += (item.price * item.count);
      });
      this.productTotal = total;
      total = (total - this.coupon_val + this.freight);
      this.total = total;
    },
    //选择地址
    selectAddress() {
      this.$router.push("/user/Addressbook?op=orderReceive");
    },
    //选择地址
    addAddress() {
      this.$router.push("/user/AddressbookAdd?op=orderReceive");
    },
    //下单
    buy() {
      //收件人信息
      if (!this.receive || this.receive.name == "") {
        return this.$message.error("请填写收件人信息", 3);
      }
      var param = {};
      param.receiverId = this.receive.id;
      param.product = this.goods;
      param.freight = this.freight;
      param.remark = this.remark;
      //创建订单
      this.api.createorder(param).then(res => {
        this.storage.setItem("order", {});
        this.$router.push("/orderPaymethod?orderNo=" + res.orderNo);
      }).catch(err => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #fff;
}

.main .m-panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
}

.main .pd10 {
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: #f5f5f5;
}

.main .m-panel .hd {
  padding-left: 21px;
  background-color: #f5f5f5;
  height: 42px;
  border-bottom: 1px solid #e8e8e8;
  line-height: 41px;
  color: #999;
  font-size: 14px;
  display: flex;
}

.main .m-panel .hd .info {
  width: 100%;
}

.main .m-panel .hd .t1 {
  width: 260px;
  text-align: center;
}

.main .m-panel .bd {
  padding: 30px 0;
  display: flex;
}

.main .m-panel .bd .l {
  width: 100%;
}

.main .m-panel .bd .r {
  padding-top: 30px;
  height: 140px;
  width: 300px;
  text-align: center;
}

.main .m-panel .bd .item {
  display: flex;
  height: 40px;
  line-height: 40px;
}

.main .m-panel .bd .item .label {
  width: 150px;
  color: #999;
  text-align: center;
}

.main .m-panel .bd .item .txt {}


.main .m-panel .goods {
  margin-bottom: 5px;
  background-color: #fffbf0;
  display: flex;
  height: 120px;
  line-height: 120px;
  padding-left: 20px;
  overflow: hidden;
}


.main .m-panel .goods .img {
  width: 180px;
}

.main .m-panel .goods .title {
  height: 40px;
  line-height: 40px;
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
}

.main .m-panel .goods .pr {
  width: 300px;
  text-align: center;
  color: #d4282d;
}

.main .m-panel .goods .t1 {
  width: 300px;
  text-align: center;
}

.main .m-panel .m-itemInfoFt {
  padding: 10px 50px;
  background-color: #f5f5f5;
  display: flex;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo {
  margin-bottom: 10px;
  display: flex;
  margin-left: auto;
  margin-right: 0;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo .label {
  width: 90px;
  text-align: right;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo .txt {
  width: 160px;
  text-align: right;
}

.main .m-panel .m-itemInfoFt .m-itemsInfo .price {
  width: 160px;
  text-align: right;
  font-weight: 700;
  color: #d4282d;
  font-size: 20px;
}

.main .m-panel .m-itemInfoFt .order {
  width: 150px;
  height: 60px;
  background-color: #b4a078;
  border: 1px solid #b4a078;
  font-size: 18px;
  margin-left: auto;
}
</style>
